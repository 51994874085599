import React, { Component } from 'react';
import MyAutoComplete from './MyAutoComplete.js';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import SearchAtoZ from './../utils/SearchAtoZ.js';


class SearchBoxBase extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };
    
    constructor(props) {
        super(props)
        this.state  = {
            autocomplete: ["default"],
            searchValue:'',
            searchResults:[]
        }
        
       this.updateFormData = this.updateFormData.bind(this)
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleAutoCompleteSel = this.handleAutoCompleteSel.bind(this);
     }
    
   
    handleSubmit(event) {
      
        event.preventDefault();       
        this.props.history.push('/search/'+this.state.searchValue.trim());
    }

    handleAutoCompleteSel(value) {
         
       switch( value.id.substr(0,1) ) {
            case 'o':
                this.props.history.push('/'+encodeURI(value.name.trim().replace(/ /g,'-')) );
                this.props.doSearch('org');
            break;
            case 'p':
                this.props.history.push('/'+value.id.substr(1)+'/'+ value.name.trim().replace(/ /g,'-').replace(/,/g,''));
                this.props.doSearch('person');
            break;
            default:
                this.props.history.push('/location/' + value.name.trim());
                this.props.doSearch('location');
            break;

       }

    }

    updateFormData(newValue){
        this.setState({
            searchValue: newValue
         });

    }
    

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
        event.preventDefault();
    };


    render() {
        const { location } = this.props;

        if ( this.props.mapRender ) { //Show the smaller search box in the map header
            return ( 
                <div className="TopBarSearch container1">
                    <form onSubmit={this.handleSubmit} className=" pt-1 pt-md-0">
                        <div className="row no-gutters">
                            <div className="col">  
                                <label htmlFor="pbsearch" className="sr-only">Map Search</label>
                                <input type="text" className="form-control" name="pbsearch" id="pbsearch" placeholder="Search the map..." value={this.state.searchValue} onChange={(e) => this.updateFormData(e.target.value)}/>
                            </div>
                            <div className="col-auto">
                                <button id="pbSearchButton" className="btn btn-secondary border-none p-2" type="button"><i className="fas fa-search text-white" aria-hidden="true" onClick={this.handleSubmit}></i>
                                    <span className="sr-only">Search</span></button>
                            </div>
                        </div>
                    </form>
                </div>

            )
        } else if ( location.pathname.trim() === '' || location.pathname.trim() === '/') { //Show the full size hero landing search
            return (
                <div id="pbJumbotron" className="has-dim-2">
                    <div className="LandingHeroSearch container">         
                        <div className="jumbotron block">
                            <div className="container pt-2 pb-4">
                                <h2 className="mt-0 text-highlight d-none d-sm-block">
                                    <strong className="highlight highlight--wrapping">
                                        Find your way at SCU.
                                    </strong>
                                  </h2>
                                  <p className="mt-4 text-highlight d-none d-md-block">
                                    <span className="highlight highlight--wrapping lead px-2">
                                       A directory of faculty, staff, organizations, and locations.
                                    </span>
                                  </p>
                            </div>
                            <div className="mainSearchCont">
                                <form onSubmit={this.handleSubmit} className="px-3 row no-gutters">
                                    <div className="col-md-6">
                                        <div className="row no-gutters">
                                            <div className="col">
                                                <label htmlFor="pbsearch" className="sr-only">Phonebook Search</label>
                                                <input type="text" className="form-control" name="pbsearch" id="pbsearch" placeholder="Search the phonebook..." value={this.state.searchValue} onChange={(e) => this.updateFormData(e.target.value)}/>
                                            </div>
                                            <div className="col-auto">
                                                <button id="pbSearchButton" className="btn btn-secondary border-none p-2" type="button"><i className="fas fa-search text-white" aria-hidden="true" onClick={this.handleSubmit}></i>
                                                <span className="sr-only">Search</span></button>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    <div className="d-none d-md-block col-md-1 text-center pt-2">
                                        <span className="text-dark h5 bg-light rounded-circle w-50 px-3 py-1 text-center">or</span>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="HomeSearch"><SearchAtoZ /></div>
                                    </div>
                                </form>                        
                            </div> 
                        </div>                                                      
                    </div>
                </div>                       
            )

        } else  { //show the smaller top line search box for phonebook with A-Z links
            return (                
                <div className="TopBarSearch bg-beige">
                    <div className="container">                   
                        <div className="py-3 row no-gutters">
                            <div className="col-md-6 text-white">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <label htmlFor="pbsearch" className="sr-only">Phonebook Search</label>
                                            <input type="text" className="form-control" name="pbsearch"  id="pbsearch"  placeholder="Search the phonebook..."  value={this.state.searchValue}  onChange={(e) => this.updateFormData(e.target.value)}/>
                                        </div>
                                        <div className="col-auto">
                                            <button id="pbSearchButton" className="btn btn-secondary border-none p-2" type="button"><i className="fas fa-search text-white" aria-hidden="true" onClick={this.handleSubmit}></i>
                                            <span className="sr-only">Search</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="d-none d-md-block col-md-1 text-center pt-2">
                                        <span className="text-dark h5 bg-light rounded-circle w-50 px-3 py-1 text-center">or</span>
                                    </div>
                            <div className="col-md-5">
                                <SearchAtoZ />
                            </div>
                        </div>
                    </div>
                </div>
                        
            )
        }
        
    }

}
const SearchBox = withRouter(SearchBoxBase);

export default SearchBox;





